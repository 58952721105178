import { add, sub, getDayOfYear, isLeapYear } from "date-fns";

class Holiday {
    constructor (date, name, is_al = false) {
        this.date = date;
        this.name = name;
        this.is_al = is_al;
    }
}

class HolidayPeriod {
    constructor (holidays) {
        this.holidays = holidays;
        this.days = holidays.length;
    }

    get alDays() {
        return this.holidays.reduce((p, c) => c.is_al ? p+1 : p, 0);
    }

    get hasAL() {
        return this.alDays > 0;
    }

    get rank() {
        return Math.round(this.alDays / (this.days - this.alDays) * 100) / 100;
    }
}

class AnnualLeaveCombo {
    constructor (beginDate, endDate, days) {
        this.beginDate = beginDate;
        this.endDate = endDate;
        this.days = days;
    }
}


function findNearestWeekends(d, previous = false) {
    let r = [], diff = 0;
    let weekday = d.getDay(); // get week day
    if (previous) {
        diff = weekday + 1;
        r.push(sub(d, {days: diff}))
        diff = weekday;
        r.push(sub(d, {days: diff}))
    } else {
        diff = 6 - weekday;
        if (diff > 0)
            r.push(add(d, {days: diff}))
        diff = 7 - weekday;
        if (diff > 0)
            r.push(add(d, {days: diff}))
    }

    return r
}

function relativeDayOfYear(d, refYear) {
    let doy = getDayOfYear(d)
    if (d.getFullYear() < refYear) {
        let daysInYear = 365 + (isLeapYear(d.getFullYear()) ? 1 : 0);
        doy -= daysInYear
    }
    if (d.getFullYear() > refYear) {
        let daysInYear = 365 + (isLeapYear(refYear) ? 1 : 0);
        doy += daysInYear
    }
    return doy
}

function findALCombo(holidayList, refYear = new Date().getFullYear()) {
    let holidayDoy = holidayList.map(e => relativeDayOfYear(e.date, refYear))
    console.log('holiday doy');
    console.log(holidayDoy);

    let alCombo = []
    for (let i = 0; i < holidayDoy.length -1; i++) {
        let diff = Math.abs(holidayDoy[i + 1] - holidayDoy[i] - 1)
        alCombo.push(new AnnualLeaveCombo(add(holidayList[i].date, {days: 1}), sub(holidayList[i + 1].date, {days: 1}), diff))
    }

    return alCombo.filter(e => e.days > 0 && e.days < 5);
}

function convertALComboToHoliday(combo) {
    return combo.reduce((previous, current) => {
        let dates = [];
        for (let i = 0; i < current.days; i++)
            dates.push(new Holiday(add(current.beginDate, {days: i}), '', true));
        return [...previous, ...dates]
    }, [])
}

function* subsetSum(comboList, target, partial = [], partialSum = 0) {
    let diff = target - partialSum;
    if (diff === 0)
        yield partial;

    if (diff < 0)
        return;

    for (let i = 0; i < comboList.length; i++) {
        yield* subsetNearSum(comboList.slice(i + 1), target, partial.concat([comboList[i]]), partialSum + comboList[i].days);
    }
}

function* subsetNearSum(comboList, target, partial = [], partialSum = 0) {
    let diff = target - partialSum;
    if (diff === 0)
        yield partial;
    
    if (diff > 0 && comboList.length >= 1 && diff < comboList[0].days)
        yield partial;

    if (diff < 0)
        return;

    for (let i = 0; i < comboList.length; i++) {
        yield* subsetNearSum(comboList.slice(i + 1), target, partial.concat([comboList[i]]), partialSum + comboList[i].days);
    }
}

function findConsectiveHolidays(holidayList, refYear = new Date().getFullYear()) {
    let r = [];
    let start = -1;

    for (let i = 0; i < holidayList.length; i++) {
        // hit the end of holidays
        if (i === holidayList.length - 1) {
            if (start !== -1) {
                const hp = new HolidayPeriod(holidayList.slice(start));
                if (hp.hasAL)
                    r.push(hp);
            }
            break;
        }
        if (getDayOfYear(holidayList[i].date, refYear) === getDayOfYear(holidayList[i+1].date, refYear) - 1) {
            // start of a holiday period
            if (start === -1)
                start = i;
        } else if (start !== -1) {
            const hp = new HolidayPeriod(holidayList.slice(start, i+1));
            if (hp.hasAL)
                r.push(hp);
            start = -1;
        }
    }

    return r;
}

export {Holiday, findALCombo, findNearestWeekends, subsetNearSum, subsetSum, convertALComboToHoliday, findConsectiveHolidays};