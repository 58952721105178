import { Holiday, findALCombo, findNearestWeekends, subsetNearSum, subsetSum, convertALComboToHoliday, findConsectiveHolidays} from './holiday'

function convertICS2Holiday(data, refYear, startDate, endDate) {
    let holidayMap = {};
  
    for (let i in data) {
      let d = data[i];

      let found = false
      switch(refYear) {
        case -1:
          if (d.start.getFullYear() >= startDate.getFullYear() &&
            d.start.getFullYear() <= endDate.getFullYear() &&
            d.start.getMonth() >= startDate.getMonth() &&
            d.start.getMonth() >= endDate.getMonth())
            found = true
          break;
        default:
          if (d.start.getFullYear() === refYear) 
            found = true
      }

      if (found) {
        holidayMap[d.start] = d.summary;
  
        // find nearest weekends
        let wks = findNearestWeekends(d.start);
        for (let i in wks) {
          holidayMap[wks[i]] = 'weekend';
        }
        wks = findNearestWeekends(d.start, true);
        for (let i in wks) {
          holidayMap[wks[i]] = 'weekend';
        }
      }
    }
  
    let holiday = [];
  
    for (let i in holidayMap) {
      holiday.push(new Holiday(new Date(i), holidayMap[i]));
    }
  
    holiday.sort((a, b) => a.date > b.date ? 1 : -1);
    return holiday
  }
  
  function findCombination(holiday, alDays, targetYear, exact=true) {
    const combo = findALCombo(holiday, targetYear);
 
    let holidayCombos = [];
    if (exact) {
      console.log('isExact')
      holidayCombos = [...subsetSum(combo, alDays)]
    } else {
      console.log('notExact')
      holidayCombos = [...subsetNearSum(combo, alDays)];
    }

    let holidayPeriodList = [];
    let holidayComboScores = [];
    for (let i in holidayCombos) {
      let rDates = convertALComboToHoliday(holidayCombos[i]);
      const newHolidays = holiday.concat(rDates)
      newHolidays.sort((a, b) => a.date > b.date ? 1 : -1);
  
      let holidayPeriods = findConsectiveHolidays(newHolidays);
      holidayPeriodList.push(holidayPeriods);
      const score = holidayPeriods.reduce((p, c) => p + c.rank, 0) / holidayPeriods.length;
      const alDays = holidayPeriods.reduce((p, c) => p + c.alDays, 0);
      const totalHolidayDays = holidayPeriods.reduce((p, c) => p + c.days, 0);
      holidayComboScores.push({idx: i, score: score, alDays: alDays, numberOfHoliday: holidayPeriods.length, totalHolidayDays: totalHolidayDays});
    }
  
    // find highest score
    // console.log('cmpFunc: ???');
    // console.log('cmpFunc: ', cmpFunc);
    // switch(cmpFunc) {
    //   case cmp.maxHoliday:
    //     holidayComboScores.sort(cmpMaxHolidaysScore);
    //     break;
    //   case cmp.maxDays:
    //     holidayComboScores.sort(cmpMaxDaysScore);
    //     break;
    //   case cmp.maxAL:
    //     holidayComboScores.sort(cmpMaxALScore);
    //     break;
    //   default:
    //     console.log('default sort')
    //     holidayComboScores.sort(cmpScore);
    // }

    // console.log('period list');
    // console.log(holidayPeriodList);
    // console.log(holidayComboScores);

    var result = {
      maxScore: [],
      maxDays: [],
      maxHoliday: [],
    }
    
    holidayComboScores.sort(cmpScore);
    let usedIdx = {}
    // find best score
    for (let i = 0; i < Math.min(holidayComboScores.length - 1, 3); i++) {
      let pos = holidayComboScores[i];
      usedIdx[pos.idx] = true;
      const bs = {
        al: holidayCombos[pos.idx],
        holidays: holidayPeriodList[pos.idx],
      }
      result.maxScore.push(bs);
    }

    console.log('u1:');
    console.log(usedIdx);
    // find maxDays
    holidayComboScores.sort(cmpMaxDaysScore);
    for (let i = 0; i < Math.min(holidayComboScores.length - 1, 3); i++) {
      let pos = holidayComboScores[i];
      if (usedIdx[pos.idx] === true) continue;

      usedIdx[pos.idx] = true;
      const bs = {
        al: holidayCombos[pos.idx],
        holidays: holidayPeriodList[pos.idx],
      }
      result.maxDays.push(bs);
    }

    console.log('u2:');
    console.log(usedIdx);
    // find maxHolidays
    holidayComboScores.sort(cmpMaxHolidaysScore);
    for (let i = 0; i < Math.min(holidayComboScores.length - 1, 3); i++) {
      let pos = holidayComboScores[i];
      if (usedIdx[pos.idx] === true) continue;

      usedIdx[pos.idx] = true;
      const bs = {
        al: holidayCombos[pos.idx],
        holidays: holidayPeriodList[pos.idx],
      }
      result.maxHoliday.push(bs);
    }

    return result
  }

const cmpScore = (a, b) => a.score > b.score ? 1: -1;
// const cmpMaxALScore = function(a, b) {
//   if (a.alDays < b.alDays) return 1;
//   if (a.alDays > b.alDays) return -1;

//   return a.score > b.score ? 1: -1;
// }
const cmpMaxHolidaysScore = function(a, b) {
  if (a.numberOfHoliday < b.numberOfHoliday) return 1;
  if (a.numberOfHoliday > b.numberOfHoliday) return -1;

  return a.score > b.score ? 1: -1;
}
const cmpMaxDaysScore = function(a, b) {
  if (a.totalHolidayDays < b.totalHolidayDays) return 1;
  if (a.totalHolidayDays > b.totalHolidayDays) return -1;

  return a.score > b.score ? 1: -1;
}

const cmp = {
  maxScore: 'maxScore',
  maxAL: 'maxAL',
  maxHoliday: 'maxHoliday',
  maxDays: 'maxDays',
}

export {convertICS2Holiday, findCombination, cmp};