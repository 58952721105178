import { getDay, getDaysInMonth, getWeekOfMonth } from 'date-fns';
import cx from 'classnames';

const HolidayHighlight = ({startCol, endCol, startRow}) => (
<div className={cx('p-0', 'items-end', 'flex', 
            {
                'col-start-1': startCol === 1,
                'col-start-2': startCol === 2,
                'col-start-3': startCol === 3,
                'col-start-4': startCol === 4,
                'col-start-5': startCol === 5,
                'col-start-6': startCol === 6,
                'col-start-7': startCol === 7,
                'col-start-8': startCol === 8,
            },
            {
                'col-end-1': endCol === 1,
                'col-end-2': endCol === 2,
                'col-end-3': endCol === 3,
                'col-end-4': endCol === 4,
                'col-end-5': endCol === 5,
                'col-end-6': endCol === 6,
                'col-end-7': endCol === 7,
                'col-end-8': endCol === 8,
                'col-end-9': endCol === 9,
            },
            {
                'row-start-1': startRow === 1,
                'row-start-2': startRow === 2,
                'row-start-3': startRow === 3,
                'row-start-4': startRow === 4,
                'row-start-5': startRow === 5,
                'row-start-6': startRow === 6,
            })}>
          <div className="h-full w-full bg-blue-400"></div>
        </div>
);

const Month = ({year, month, holidays}) => {
    function cGetDay(d) {
        let day = getDay(d);
        if (day === 0) return 7;
        return day;
    }

    const monthDays = getDaysInMonth(new Date(year, month-1));
    let offset = cGetDay(new Date(year, month-1, 1))
    const rowNumber = getWeekOfMonth(new Date(year, month-1, monthDays), {weekStartsOn: 1})

    let holidayHighlights = [];

    let startRow = -1, startCol = -1, endCol = -1;
    for (let i = 0; i < holidays.length; i++) {
      const h = holidays[i].date
      const hRow = getWeekOfMonth(h, {weekStartsOn: 1})
      
      if (startRow === -1)  {
        startRow = hRow
        startCol = cGetDay(h);
      }

      if (hRow !== startRow || i === holidays.length - 1) {
        endCol = cGetDay(holidays[i - 1].date) + 2;
        holidayHighlights.push(<HolidayHighlight startCol={startCol} endCol={endCol} startRow={startRow}/>);

        startRow = hRow;
        startCol = cGetDay(h);
        if (i === holidays.length - 1) {
            holidayHighlights.push(<HolidayHighlight startCol={startCol} endCol={startCol+1} startRow={startRow}/>);
        } else 
          endCol = -1;
      }
    }

    return (
      <div>
        <span className="pl-2">{month} 月</span>
        <div className="grid grid-cols-7 gap-1 text-center px-1">
          <div className="text-xs font-bold">M</div>
          <div className="text-xs font-bold">T</div>
          <div className="text-xs font-bold">W</div>
          <div className="text-xs font-bold">T</div>
          <div className="text-xs font-bold">F</div>
          <div className="text-xs font-bold">S</div>
          <div className="text-xs font-bold text-red-500">S</div>
        </div>
        <div className="relative grid grid-cols-7 p-1 gap-1 text-center">
          <div className={"absolute inset-0 grid grid-cols-7 p-1 grid-rows-"+rowNumber}>
            {holidayHighlights}
          </div>

          <div className={'text-xs z-20 col-start-'+offset}>1</div>
          {[...Array(monthDays - 1).keys()].map(el => <div className="text-xs z-20">{el+2}</div>)}
        </div>
      </div>
    );
}

const Year = ({year, holidays}) => {
    return (
      <div className="grid grid-cols-2 gap-2">
        {[...Array(12).keys()].map(el => {
          let h = [];
          if (holidays) {
            h = holidays.filter(e => e.date.getMonth() === el)
            // console.log((el + 1) + ': ', h);
          }
          return <Month year={year} month={el+1} holidays={h}/>
        })}
      </div>
    );
}

export {Year, Month};
