import './App.css';
import { useEffect, useState } from 'react';
import ical from 'ical';
import { convertICS2Holiday, findCombination} from './util/al_calculation';
import { format } from 'date-fns';
import cx from 'classnames'
import { Year } from './calendar';
// import calendarIcon from './img/calendar2.jpg'

// var hk_holiday_ics = "https://www.1823.gov.hk/common/ical/tc.ics";
const local_ics = {
  "hk_ics": "/tc.ics",
}

const useInput = initVal => {
  const [value, setValue] = useState(initVal);

  return {
    value,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: e => setValue(e.target.value)
    }
  }
}

// const CloseButton = ({onClick}) => (
// <button type="button" onClick={onClick} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
//   <span className="sr-only">Close menu</span>
//   <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
//   </svg>
// </button>
// );


const ButtonGroup = ({ buttons, buttonValues = null, onClick }) => {
  const [clickedId, setClickedId] = useState(0);
  const click = (e, id) => {
    setClickedId(id);
    onClick(e.currentTarget.name);
  }

  function getName(i) {
    return buttonValues === null ? buttons[i] : buttonValues[i];
  }

  return (
    <div className="btn-group">
      {buttons.map((label, i) => (
        <button
          key={i}
          name={getName(i)}
          onClick={(e) => click(e, i)}
          className={cx('btn', 'btn-outline', 'btn-sm', { 'btn-active': clickedId === i })}
        >
          {label !== -1 ? label :
            "自選"
            //  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 stroke-current">      
            //    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>                    
            //  </svg>
          }
        </button>
      ))}
    </div>
  );
}

const initState = {
  icsPath: local_ics["hk_ics"],
  icsData: null,
  year: new Date().getFullYear() + 1,
  loading: false,
  result: null,
}

function App() {
  const { value: alDays, reset: resetALDays, bind: bindALDays } = useInput('');
  const { value: startDate, reset: resetStartDate, bind: bindStart } = useInput(new Date());
  const { value: endDate, reset: resetEndDate, bind: bindEnd } = useInput('');
  const [appState, setAppState] = useState(initState);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    async function fetchICS() {
      let r = await fetch(appState.icsPath);
      const b = await r.text();
      const data = ical.parseICS(b);
      setAppState({ ...appState, icsData: data });
    }

    fetchICS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function calculate() {
    console.log('clicked calculate')
    console.log('start: ', startDate);
    console.log('end: ', endDate);
    if (alDays <= 0) return;
    setAppState({ ...appState, loading: true });
    setTimeout(() => {
      const holidays = convertICS2Holiday(appState.icsData, appState.year)
      console.log('year: ', appState.year, ' alDays: ', alDays);
      const result = findCombination(holidays, alDays, appState.year);
      console.log(result);
      setAppState({ ...appState, loading: false, result: result });
    }, 100);
  }

  function switchYear(year) {
    setAppState({ ...appState, year: parseInt(year) });
  }

  function resetResult() {
    setAppState({ ...appState, result: null })
    resetALDays();
    resetStartDate();
    resetEndDate();
  }

  return (
    <div className="container p-5">
      <div>
        Description here
      </div>
      <div className="card bg-base-100 shadow-2xl p-4">
        <div className="form-control">
          <label className="label">
            <span>時段</span>
          </label>
          <ButtonGroup init={appState.year} buttons={[currentYear, currentYear + 1, -1]} onClick={switchYear} />
          {appState.year === -1 ?
            <>
              <input type="month" className="input input-bordered w-full my-3"
                placeholder="開始日期" min='2021-01' max='2022-12' {...bindStart} />
              <label className="text-center">至</label>
              <input type="month" className="input input-bordered w-full my-3"
                placeholder="結束日期" min='2021-01' max='2022-12' {...bindEnd} />
            </> : <></>}
          <label className="label">
            <span>年假</span>
          </label>
          <input type="number" className="bg-gray-100 text-center input mb-8" {...bindALDays}
            placeholder="輸入你的年假日數"
          />

        </div>

        <label for="my-modal-2" className="btn btn-primary modal-button">確定</label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" onClick={calculate} />
        {/* result modal */}
        <div className="modal">
          <div className="modal-box max-h-full pb-1">
            <HolidayWrapper {...appState.result} alDays={alDays} resetResult={resetResult} />
          </div>
        </div>
      </div>
    </div>
  );
}

// const HolidayStat = ({ al, holidays, alDays }) => (
//   <>
//     {al ?
//       <div className="flex flex-row bg-gray-100 rounded px-2 py-3 mb-2">
//         <div className="flex-1">年假：{alDays}日</div>
//         <div className="flex-1">剩餘{alDays - al.reduce((p, c) => p + c.days, 0)}日</div>
//       </div>
//       : <div></div>
//     }
//   </>
// );

const HolidayWrapper = ({ maxScore, maxDays, maxHoliday, resetResult }) => {
  const [display, setDisplay] = useState(null);

  useEffect(() => {
    if (maxScore) {
      let filterLabels = ['推薦'];
      if (maxDays.length >= 1) filterLabels.push('日數');
      if (maxHoliday.length >= 1) filterLabels.push('假期');
      setDisplay({ combo: maxScore[0], filterLabels: filterLabels, calendar: false })
    } else {
      setDisplay(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxScore]);

  const click = (val) => {
    console.log(val);
    switch (val) {
      case '推薦':
        setDisplay({ ...display, combo: maxScore[0] })
        break;
      case '日數':
        setDisplay({ ...display, combo: maxDays[0] })
        break;
      case '假期':
        setDisplay({ ...display, combo: maxHoliday[0] })
        break;
      default:
    }
  }

  const toggleCalendar = () => setDisplay({...display, calendar: !display.calendar})

  if (display === null) return (
    <>
      <div className="modal-action mb-6 mt-0">
        <label for="my-modal-2" className="btn btn-sm" onClick={resetResult}>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </label>
      </div>
      <div className="modal-body flex justify-center pb-4">
        <button class="btn btn-circle loading"></button> 
      </div>
    </>)
  
  const dh = display.combo.holidays.reduce((p, c) => p.concat(c.holidays), [])

  return (
    <>
      <div className="modal-action mb-6 mt-0">
        <button className={cx("btn", "btn-sm", 'btn-outline', {"btn-active": display.calendar})} onClick={toggleCalendar}>
          日曆
        </button>
        <ButtonGroup buttons={display.filterLabels} onClick={click} />
        <label for="my-modal-2" className="btn btn-sm" onClick={resetResult}>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </label>
      </div>
      <div className="modal-body">
        {display.calendar ? 
        <Year year={2022} holidays={dh}/> :
        <Holiday {...display.combo} />
        }
      </div>
    </>
  );
}

const Holiday = ({ al, holidays, alDays }) => (
  <>
    {/* <div className="flex flex-row bg-gray-100 rounded px-2 py-3 mb-2">
      <div className="flex-1">年假：{alDays}日</div>
      <div className="flex-1">剩餘{alDays - al.reduce((p, c) => p + c.days, 0)}日</div>
    </div> */}
    <div className="flex flex-row pt-5 pb-1 text-gray-500">
      <div className="flex-1">假期組合:</div>
      <div>總共：{holidays.reduce((p, c) => p + c.days, 0)}日假期</div>
    </div>
    <div className="divide-y">
      {holidays.map((hp, idx) => <div className="collapse" key={idx}>
        <input type="checkbox"/>
        <div className="collapse-title font-medium">
          <div className="flex flex-row">
            <div className="flex-1">
              {hp.holidays.reduce(function (p, c) {
                if (p !== '') return p;
                return c.name === '' || c.name === 'weekend' ? '' : c.name;
              }, '')}
            </div>
            <div className="flex-2 text-gray-400 text-sm">{hp.days}日假期</div>
          </div>
          <div>
            {format(hp.holidays[0].date, 'M月d日')} 至 {format(hp.holidays[hp.holidays.length - 1].date, 'M月d日')}
          </div>
        </div>
        <div className="collapse-content">
          <div className="flex flex-wrap divide-x text-sm text-gray-500 mt-1">
            請{hp.alDays}日年假：
            {hp.holidays.filter(e => e.is_al).map(el => <div className="px-1">{format(el.date, 'M月d日')}</div>)}
          </div>
        </div>
      </div>)}
    </div>
  </>
);

export default App;
